// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../media/bar-charts.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-caa-fundamentals .error-page {\n    display: flex;\n    align-content: center;\n    align-items: center;\n    position: absolute;\n    top: calc(50% - 40px / 2);\n    left: calc(50% - 100px);\n    min-width: 200px;\n}\n\n.app-caa-fundamentals .error-icon {\n    background: center center url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n    height: 40px;\n    width: 32px;\n}\n\n.app-caa-fundamentals .error-page .error-message {\n    margin-left: 16px;\n    font-size: 13px;\n    line-height: 16px;\n    font-weight: 400;\n    color: #6A7787;\n}", "",{"version":3,"sources":["webpack://./src/styles/ErrorPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,2EAAgE;IAChE,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".app-caa-fundamentals .error-page {\n    display: flex;\n    align-content: center;\n    align-items: center;\n    position: absolute;\n    top: calc(50% - 40px / 2);\n    left: calc(50% - 100px);\n    min-width: 200px;\n}\n\n.app-caa-fundamentals .error-icon {\n    background: center center url(../media/bar-charts.svg) no-repeat;\n    height: 40px;\n    width: 32px;\n}\n\n.app-caa-fundamentals .error-page .error-message {\n    margin-left: 16px;\n    font-size: 13px;\n    line-height: 16px;\n    font-weight: 400;\n    color: #6A7787;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
