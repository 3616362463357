import { useTranslation } from '@ig-caa/i18n'
import styled from 'styled-components'
import '../styles/ErrorPage.css'
import { Trans } from 'react-i18next'
import { ErrorProps } from '../utils/getErrorTypes'
import { ExternalLink } from 'ig-phoenix'
import React from 'react'
import { NoFundamentals } from '@ig-caa/media'

export default function ErrorPage ({ pageType, marketDataUrl }: ErrorProps): JSX.Element {
  const { t } = useTranslation()

  if (pageType === 'EMPTY_DATA') {
    return (
      <Page data-testid='error-no-data'>
        <NoFundamentals />
        <ErrorText>{t('no-data')}</ErrorText>
      </Page>
    )
  }

  return (
    <Page data-testid='loading-error'>
      <ErrorText><Trans i18nKey="loading-error" components={ { br: <br/> } }/>
        <br/>
        <MoreLinkElement data-testid='more-link' className='more-data-link'>
          <ExternalLink
            label={t('more-data-link')}
            href={marketDataUrl}
            target='_blank'/>
        </MoreLinkElement>
      </ErrorText>
    </Page>
  )
}

const Page = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(50% - 40px / 2);
  left: calc(50% - 100px);
  min-width: 200px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.text.secondary.value};
`

const MoreLinkElement = styled.span`
  font-weight: 400;
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  line-height: ${({ theme }) => theme.size.link.medium.lineHeight.value};
`

export const ErrorText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  text-align: center;
  align-self: center;
  margin-left: 16px;
`
