import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import '../styles/App.css'
import { AppProps, TrackMarketDataInteraction } from './AppProps'
import Fundamentals from './Fundamentals'
import { Providers } from '@ig-caa/mfe-infrastructure'

/* istanbul ignore next */
const DEFAULT_TRACKING: TrackMarketDataInteraction = (
  eventAction: string,
  epic,
  instrumentName) => {
  console.log('tracked:', eventAction, epic, instrumentName)
}

export function App ({ trackMarketDataInteraction = DEFAULT_TRACKING, epic = '', instrumentName = '', ...props }: AppProps): JSX.Element {
  const { theme, env, newsSymbol, marketDataUrl, cst, sessionData: { clientLocale } } = props
  const localeLoader = async (locale: string) => await import(`../locales/${locale}.json`)
  const supportedLanguages = ['en-GB', 'ja-JP', 'de-DE', 'es-ES', 'fr-FR', 'it-IT', 'nl-NL', 'no-NO', 'pt-PT', 'sv-SE', 'zh-CN', 'zh-TW']

  return (
    <Providers
      appName='fundamentals'
      clientLocale={clientLocale}
      localeLoader={localeLoader}
      supportedLanguages={supportedLanguages}
      theme={theme}
    >
      <div className={`app ${props.theme} app-caa-fundamentals`} data-testid='app'>
        <Fundamentals
          theme={theme}
          env={env}
          newsSymbol={newsSymbol}
          cst={cst}
          locale={clientLocale}
          marketDataUrl={marketDataUrl}
          trackMarketDataInteraction={trackMarketDataInteraction}
          instrumentName={instrumentName}
          epic={epic}/>
      </div>
    </Providers>
  )
}

const lifecycles = singleSpaReact({ React, ReactDOM, rootComponent: App })
export const { bootstrap, mount, unmount, update } = lifecycles
export default lifecycles
