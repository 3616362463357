import { ENVIRONMENTS } from '../components/AppProps'
import { ERROR_TYPE } from './getErrorTypes'

const ENDPOINTS = (newsSymbol: string) => ({
  prod: `https://insights-api.ig.com/fundamentals/top-6/${newsSymbol}`,
  test: `https://net-insights-api.ig.com/fundamentals/top-6/${newsSymbol}`,
  uat: `https://web-insights-api.ig.com/fundamentals/top-6/${newsSymbol}`,
  demo: `https://demo-insights-api.ig.com/fundamentals/top-6/${newsSymbol}`,
  local: `https://net-insights-api.ig.com/fundamentals/top-6/${newsSymbol}`
})

export interface FundamentalData {
  items: FundamentalItem[]
  currency: string | null
}

export interface FundamentalItem {
  heading: string
  unit: string
  type: string
  value: string
}

export default async function fetchFundamentals (env: keyof typeof ENVIRONMENTS, cst: string, newsSymbol: string): Promise<FundamentalData> {
  if (newsSymbol === '') {
    throw new Error('missing newsSymbol')
  }
  try {
    const response: Response = await fetch(`${ENDPOINTS(newsSymbol)[env]}`, {
      method: 'GET',
      headers: {
        cst
      }
    })
    if (!response.ok || response.status === 204) throw new Error(`${response.status}`)

    const responseData = await response.json()
    const { NHIG, NLOW, MKTCAP, YIELD, APENORM, TTMBEPSXCL } = responseData.data
    // if ([NHIG, NLOW, MKTCAP, YIELD, APENORM, TTMBEPSXCL].every(variable => variable === null)) throw new Error(`${response.status}`)
    // const values = [NHIG?.value, NLOW?.value, MKTCAP?.value, YIELD?.value, APENORM?.value, TTMBEPSXCL?.value]
    // if (values.every(variable => variable === null || variable === '')) throw new Error(`${response.status}`)

    return {
      currency: responseData.currency,
      items: [
        { heading: 'market-cap', ...MKTCAP },
        { heading: 'normalised-pe', ...APENORM },
        { heading: 'dividend-yield', ...YIELD },
        { heading: 'eps-12-month-trailing', ...TTMBEPSXCL },
        { heading: 'price-12-month-high', ...NHIG },
        { heading: 'price-12-month-low', ...NLOW }
      ]
    }
  } catch (error) {
    if ((error as Error).message === '204') {
      throw new Error(ERROR_TYPE.EMPTY_DATA)
    }
    throw new Error(ERROR_TYPE.DEFAULT)
  }
}
