import { useTheme } from 'styled-components'
import React from 'react'

export default function NoFundamentals (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.icons.disabled.value
  return (
    <svg width="32" height="40" viewBox="0 0 32 40" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
        d={'M28 0C25.7908 0 24 1.79086 24 4V36C24 38.2091 25.7908 40 28 40C30.2091 40 32 38.2091 32 36V4C32 1.79086 30.2091' +
          ' 0 28 0ZM12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16V36C20 38.2092 18.2091 40 16 40C13.7909 40' +
          ' 12 38.2092 12 36V16ZM0 28C0 25.7908 1.79086 24 4 24C6.20914 24 8 25.7908 8 28V36C8 38.2091 6.20914 40 4 40C1.79086' +
          ' 40 0 38.2091 0 36V28Z'
        }
        fill="#B5BDBE"/>
    </svg>
  )
}
