import styled from 'styled-components'
import { Theme } from './AppProps'
import { fundamentalsCurrencyFormatter } from '../../../utils/src/currencyFormatter'

interface ValueDisplayProps {
  value: string
  unit: string
  type: string
  theme: Theme
  currency: string | null
  colourise?: boolean
  locale: string
}

const Value = styled.span`
  font-size: ${({ theme }) => theme.size.heading.h1.text.value};
  line-height: ${({ theme }) => theme.size.heading.h1.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
`

const PositiveValue = styled.div`
  color: ${({ theme }) => theme.color.text.positiveMarketMovement.value};
`

const NegativeValue = styled.div`
  color: ${({ theme }) => theme.color.text.negativeMarketMovement.value};
`

export default function ValueDisplay (props: ValueDisplayProps): JSX.Element {
  const { colourise, value, locale, currency, unit, type } = props
  const parsedValue = parseFloat(value)

  if (Number.isNaN(parsedValue) || (!props.currency && props.type === 'C')) {
    return <div data-testid='not-colourised'><Value>-</Value></div>
  }

  const positiveValue = parsedValue > 0
  const content = (props: ValueDisplayProps): JSX.Element => (
    <Value> {positiveValue ? '' : '-'}{fundamentalsCurrencyFormatter(locale, currency, Number(value), 2, unit, type)}</Value>
  )

  if (colourise === true) {
    if (positiveValue) {
      return <PositiveValue className='colourised-positive' data-testid='colourised-positive'>{content(props)}</PositiveValue>
    } else {
      return <NegativeValue className='colourised-negative' data-testid='colourised-negative'>{content(props)}</NegativeValue>
    }
  }
  return <div data-testid='not-colourised'>{content(props)}</div>
}
