import { useTranslation } from '@ig-caa/i18n'
import styled from 'styled-components'
import { Informative, ExternalLink, Tooltip, Spinner } from 'ig-phoenix'
import '../styles/Fundamentals.css'
import { FundamentalProps } from './AppProps'
import ValueDisplay from './ValueDisplay'
import ErrorPage from './ErrorPage'
import { useQuery } from '@tanstack/react-query'
import fetchFundamentals, { FundamentalData } from '../utils/fetchFundamentals'
import { ERROR_TYPE } from '../utils/getErrorTypes'
import React from 'react'

export default function Fundamentals (props: FundamentalProps): JSX.Element {
  const { t } = useTranslation()
  const { env, cst, theme, newsSymbol, locale, marketDataUrl, trackMarketDataInteraction, instrumentName, epic } = props
  const { isLoading, error, data } = useQuery<FundamentalData, any>(['fundamentals', newsSymbol], async () => await fetchFundamentals(env, cst, newsSymbol))

  if (isLoading) {
    return <div className="fundamentals-loading" data-testid='isLoading'><Spinner radius='40px' /></div>
  }
  if (error?.message === ERROR_TYPE.EMPTY_DATA) {
    return <ErrorPage pageType={ERROR_TYPE.EMPTY_DATA} marketDataUrl=''/>
  }
  if (error?.message === ERROR_TYPE.DEFAULT) {
    return <ErrorPage pageType={ERROR_TYPE.DEFAULT} marketDataUrl={marketDataUrl}/>
  }

  return (
    <>
      <AppContainer className='fundamentals' data-testid='fundamentals'>
        <SummaryElement className='fundamentals-summary' data-testid='summary'>
          {t('fundamentals-summary')}
        </SummaryElement>
        <div className="cards-container">
          {
            data?.items?.map(({ heading, unit, value, type }, index) => {
              return <Card key={heading} data-testid={`card-${index + 1}`}>
                <CardHeader>
                  {t(heading)}
                  <Tooltip
                    position='bottom'
                    zIndex='9'
                    tipPosition='-140px'
                    top='20px'
                    width='368px'
                    tooltipRender={() =>
                      <>
                        <TooltipHeader>{t(heading)}</TooltipHeader>
                        <TooltipText>{t(`${heading}-tooltip`)}</TooltipText>
                      </>
                    }
                  >
                    <span className='info-icon' data-testid={`icon-for-${heading}`}><Informative/></span>
                  </Tooltip>
                </CardHeader>
                <ValueDisplay
                  unit={unit}
                  value={value}
                  type={type}
                  theme={theme}
                  currency={data.currency}
                  colourise={heading === 'eps-12-month-trailing'}
                  locale={locale}
                />
              </Card>
            })
          }
        </div>
        <MoreLinkElement data-testid='more-link' className='more-data-link'>
          <ExternalLink
            data-testid='more-data-link'
            label={t('more-data-link')}
            href={marketDataUrl}
            target='_blank'
            onClick={function () {
              trackMarketDataInteraction(instrumentName, epic, 'Open Market Data link from Fundamentals (Market Details Page)')
            }}
          />
        </MoreLinkElement>
      </AppContainer>
    </>
  )
}

const AppContainer = styled.div`
  background-color: ${({ theme }) => theme.color.background.primarySurface.value};
  color: ${({ theme }) => theme.color.text.primary.value};
`

const SummaryElement = styled.span`
  font-weight: 400;
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  line-height: ${({ theme }) => theme.size.body.small.lineHeight.value};
  margin-right: 4px;
`

const MoreLinkElement = styled.span`
  font-weight: 400;
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  line-height: ${({ theme }) => theme.size.link.medium.lineHeight.value};
`

const Card = styled.div`
  background: ${({ theme }) => theme.color.background.secondarySurface.value};
  padding: 8px 8px 12px 8px;
  border: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  border-radius: 4px;
  min-height: 66px;
  width: 230px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  box-sizing: border-box;
`

const CardHeader = styled.span`
  font-size: ${({ theme }) => theme.size.heading.h5.text.value};
  line-height: ${({ theme }) => theme.size.heading.h5.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h5.weight.value};
  color: ${({ theme }) => theme.color.text.primary.value};
  height: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const TooltipHeader = styled.p`
  margin: 0 0 4px;
  font-weight: ${({ theme }) => theme.font.heading.h5.weight.value};
  font-size: ${({ theme }) => theme.size.body.small.text.value};
`

const TooltipText = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  font-weight: ${({ theme }) => theme.font.body.weight.value};
`
